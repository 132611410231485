.infracontainer h1{
  text-align: center;
}

.whtclr {
margin-left: 22px;
margin-right: 22px;
margin-top: 24px;
}

.schoolbg{  
  background: linear-gradient(rgb(64 63 63 / 80%), rgb(81 80 80 / 80%)), url(../../assests/images/gallery/School_img.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.computerbg{  
  background: linear-gradient(rgb(64 63 63 / 80%), rgb(81 80 80 / 80%)), url(../../assests/images/gallery/comlab.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
}
.librarybd{  
  background: linear-gradient(rgb(64 63 63 / 80%), rgb(81 80 80 / 80%)), url(../../assests/images/gallery/library.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
}
.sportsbg{  
  background: linear-gradient(rgb(64 63 63 / 80%), rgb(81 80 80 / 80%)), url(../../assests/images/gallery/sports.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
}
.selfbg{  
  background: linear-gradient(rgb(64 63 63 / 80%), rgb(81 80 80 / 80%)), url(../../assests/images/gallery/self.PNG);
  background-size: cover;
  background-repeat: no-repeat;
}
.exambg{  
  background: linear-gradient(rgb(64 63 63 / 80%), rgb(81 80 80 / 80%)), url(../../assests/images/gallery/dedicated_faculty.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
}
.infracardhight{
  height: 454px !important;
}

.content-img-left img:hover{
  transform: scale(1.1);
}


* {
  transition: 0.5s;
}


.align-middle {
  position: relative;
  top: 50%;
  /* transform: translateY(-50%); */
}

/* .column {
  margin-top: 30rem;
  padding-left: 3rem;
  &:hover {
    padding-left: 0;
    .card .txt {
      margin-left: 1rem;
      h1,
      p {
        color: rgba(255, 255, 255, 1);
        opacity: 1;
      }
    }
    a {
      color: rgba(255, 255, 255, 1);
      &:after {
        width: 10%;
      }
    }
  }
} */
/* .card {
  min-height: 170px;
  margin: 28px;
  padding: 1.7rem 1.2rem;
  border: none;
  border-radius: 0;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0.05rem;
  font-family: "Oswald", sans-serif;
  box-shadow: 0 0 21px rgba(0, 0, 0, 0.27);
  .txt {
    z-index: 1;
    h1 {
      font-size: 1.5rem;
      font-weight: 600;
      text-transform: capitalize;
      color: white;
    }
    p {
      font-size: 0.9rem;
      font-weight: 600;
      font-family: "Open Sans", sans-serif;
      letter-spacing: 0rem;
      line-height: 20px;
      margin-top: 33px;
      opacity: 0;
      color: rgba(255, 255, 255, 1);
    }
  }
  a {
    z-index: 3;
    font-size: 0.7rem;
    color: rgba(0, 0, 0, 1);
    margin-left: 1rem;
    position: relative;
    bottom: -0.5rem;
    text-transform: uppercase;
    &:after {
      content: "";
      display: inline-block;
      height: 0.5em;
      width: 0;
      margin-right: -100%;
      margin-left: 10px;
      border-top: 1px solid rgba(255, 255, 255, 1);
      transition: 0.5s;
    }
  }
  .ico-card {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
 

} */
.fontIcon{
  opacity: 0.4;
  width: 50% !important;
    height: 60% !important;
    color: rgba(255, 255, 255, 1);
    position: relative;
    right: -40%;
    top: 20%;
}

/* YourComponent.module.css */
.column {
  position: relative;
  overflow: hidden;
}

.fontIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-30%, -30%);
  transition: all 0.3s ease;
}

.column:hover .fontIcon {
  height: 70%;
  width: 60%;
  top: 15%;
}
/**/
.morebtn{
  cursor: pointer;
}

.txtFirst{
  z-index: 1;
  text-align: center;
  font-size: 1.5rem;
      font-weight: 300;
      /* text-transform: uppercase; */
}



/* --------pop box css------ */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modal-content {
  background-color: white !important;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 90vw;
  height: 90vh;
}
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

.obg{
  background-image:url('../../assests/images/obg.gif') ;
  background-size: cover;
  background-position:center ;
  background-repeat: no-repeat; 
}
@media (max-width: 990px) {  

.content-img-left img {
  width: 100%; 
}
}