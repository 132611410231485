.testimonial-listing .testimonial-box {
  margin-bottom: 30px;
}
.testimonial-box {
  border: 1px solid #dadada;
  padding: 0px 15px 15px 15px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  background: #fff;
  min-height: 400px;
}
.testimonial-box:hover{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px !important;
}
.testimonial-box .color {
  height: 186px;
  margin: -100px -15px 0px -15px;
  border-radius: 0 0 50% 50%/0 0 100% 100%;
}

.testimonial-box .image {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50%;
  border: 4px solid #e7ebfb;
  margin: -60px auto 10px auto;
  position: relative;
  z-index: 2;
}
.testimonial-box .image img {
  width: 93px;
  height: 93px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #fff;
  object-position: top;
}
.testimonial-box blockquote {
  margin: 0px 0px 15px;
  height: 105px;
  overflow: auto;
  border: none;
  padding: 0px;
  border: none;
}
.testimonial-box blockquote p {
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  margin: 0px;
}
.test_header {
  margin: 40px;
}
.topperDetail{
  text-align: center;
}
.topperDetail p{
  color: #000000;
}
