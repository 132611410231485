
/* contact-section */



.wpo-contact-pg-section {
  padding-bottom: 0px;
 
}


.wpo-contact-pg-section .office-info {
  padding-bottom: 100px;
  margin-top: 35px;
}

/* office items */

.wpo-contact-pg-section .office-info .office-info-item {
  box-shadow: 0 5px 15px 0 rgba(62,65,159,.1);
  padding: 40px 5px;
  text-align: center;
  background-color: white;
  margin-top: 20px;
 
}

/* office info icone  */

.wpo-contact-pg-section .office-info .office-info-item .office-info-icon {
  /* background: #f5f5f5; */
  border-radius: 50%;
  /* border: 1px solid  yellow; */
  height: 85px;
  line-height: 85px;
  margin: 0 auto;
  text-align: center;
  width: 85px;
}


.wpo-contact-pg-section .office-info .office-info-item .office-info-icon .icon .fi:before {
  font-size: 35px;
}

/* i[class*=" flaticon-"]:before, i[class^=flaticon-]:before {
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Flaticon !important;
  font-style: normal;
  font-variant: normal;
  font-weight: 400 !important;
  text-transform: none;
} */


.wpo-contact-pg-section .office-info .office-info-item .office-info-text h2 {
  font-size: 30px;
  font-weight: 500;
  margin: 20px 0;
}


.wpo-contact-pg-section .office-info .office-info-item .office-info-text p {
  margin-bottom: 0;
  color: #625f71;
    font-size: 18px;
    line-height: 1.8em;
   
}


/* contact title */

.wpo-contact-pg-section .wpo-contact-title {
  margin: 0 auto 50px;
  max-width: 440px;
  text-align: center;
}
.contact-container{
  padding-left: 25px;
  padding-right: 25px;
}
/* 
contact form area */

.wpo-contact-pg-section .wpo-contact-form-area {
  background: #fff;
  box-shadow: 0 1px 15px 0 rgba(62,65,159,.1);
  margin-bottom: -125px;
  margin-left: 150px;
  margin-right: 150px;
  padding: 50px;
  position: relative;
  z-index: 99;
  border-radius: 15px;
}

.wpo-contact-pg-section form {
  margin: 0 -15px;
  overflow: hidden;
}

/* .wpo-contact-pg-section form>div {
  float: left;
  margin: 0 15px 25px;
} */

.wpo-contact-pg-section .form-field {
  margin-bottom: 20px;
}

.wpo-contact-pg-section form input, .wpo-contact-pg-section form select, .wpo-contact-pg-section form textarea {
  background: transparent;
  border: 1px solid #ebebeb;
  border-radius: 0;
  box-shadow: none!important;
  height: 50px;
  padding-left: 25px;
  width: 100%;
}




 /* text area  */
.wpo-contact-pg-section form textarea {
  height: 180px;
  padding-top: 15px;
}
.section-padding{
  background: url("../../assests/contact/contactbg.svg")no-repeat center center;
  background-size: cover; 
}

.wpo-contact-pg-section form textarea {
  background: transparent;
  border: 1px solid #ebebeb;
  border-radius: 0;
  box-shadow: none!important;
  height: 150px;
  padding-left: 25px;
  width: 100%;
}

/* submit area  */

.wpo-contact-pg-section form .submit-area {
  margin-top: 20px;
  
  text-align: center;
  width: 100%;
}


.theme-btn, .theme-btn-s2, .view-cart-btn {
  /* -webkit-backface-visibility: hidden; */
  background: #f2bc00;
  border: 0;
  border-radius: 30px;
  color: #1a1729;
  display: inline-block;
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 500;
  font-weight: 600;
  padding: 15px 35px;
  position: relative;
  text-transform: capitalize;
  z-index: 1;
  background:       linear-gradient(178deg, #2041e4, #0099ff6b); 
}


.theme-btn:hover, .theme-btn-s2:hover, .view-cart-btn:hover {
  background: linear-gradient(135deg, #5346f0, #afafafc4); 
  color: #fff; 
}
/* map section */
.wpo-contact-map-section .wpo-contact-map {
  height: 550px;
 
}

.wpo-contact-map-section iframe {
  border: 0;
  height: 100%;
  outline: 0;
  width: 100%;
}
.wpo-contact-map-section{
  padding-bottom: 0px;
}

.gifimg{
  width: 100%;
  border-radius: 50%;
  /* overflow: hidden;   */
}

@media (min-width: 320px) and (max-width: 1247px) {
  .office-info-item {
    word-wrap: break-word;
    height: 370px;  
}
.wpo-contact-pg-section .wpo-contact-form-area {
  margin-left: 0px;
  margin-right: 0px;
}

}
