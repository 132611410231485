.acadmicherosec{
  background-image: url(../../assests/images/gallery/acadmicbg.jpeg) !important;
}
.acadmicherocnt{
  margin-top: 100px;
}
.acadmicherocnt h2{
  color: #fff !important;
}
.acadmicherocnt h2 span{
  background: none !important;
}