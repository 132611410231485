@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Andada+Pro:wght@400;500;600;700;800&display=swap");

/* =========== base style ========= */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

section,
footer {
  padding-top: 50px;
  padding-bottom: 50px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 2rem;
  line-height: 50px;
  font-family: "Poppins", sans-serif;
  color: #0a2b1e;
  font-weight: 600;
}

p {
  color: #596b65;
  font-size: 1rem;
  line-height: 35px;
  font-family: "Andada Pro", serif;
  font-weight: 400;
}

button.btn {
  background: #000000;
  color: #fff;
  /* padding: 7px 65px; */
  padding-top: 15px;
  padding-bottom: 15px;
  width: 297px;
  border-radius: 10px;
  transition: background-color 0.3s ease; /* Adding transition effect */
  font-weight: bold;
}

button.btn:hover {
  background-color: #ffffff; /* Change background color on hover */
  color: rgb(0, 0, 0);
  color: rgb(0, 0, 0); /* Change text color on hover */
  border: 1px solid black; /* Add black border on hover */
  font-weight: bold;
}


i {
  color: #067fcb;
}


@media (min-width: 576px){
  .homecontainer{
    max-width: 100% !important;
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
}