.newsletter {
  background: #000000;
  background: url('../../assests/images/gallery/9.PNG') center/cover; /* Specify the path to your image */

  padding: 60px 0px;
  border-radius: 15px;
}

.overlay{
  background: #0000009e;
}

.newsletter h2 {
  color: #fff;
}
.subscribe input {
  border: none;
  outline: none;
}

.subscribe {
  padding: 7px 10px;
  background: #fff;
  width: 45%;
  margin: auto;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* CSS */
.readable-animation {
  font-size: 18px;
  font-weight: bolder;
  color: #ffffff;
  text-align: center;
  animation: fadeIn 1s ease-in-out; /* Apply fadeIn animation */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

