.card-custom {
    overflow: hidden;
    min-height: 450px;
    box-shadow: 0 0 15px rgba(10, 10, 10, 0.3);
  }

  
  .card-custom-img {
    height: 200px;
    min-height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-color: inherit;
  }
  
  /* First border-left-width setting is a fallback */
  .card-custom-img::after {
    position: absolute;
    content: '';
    top: 161px;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-top-width: 40px;
    border-right-width: 0;
    border-bottom-width: 0;
    border-left-width: 545px;
    border-left-width: calc(575px - 5vw);
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: inherit;
  }
  
  .card-custom-avatar img {
    border-radius: 50%;
    box-shadow: 0 0 15px rgba(10, 10, 10, 0.3);
    position: absolute;
    /* top: 100px; */
    top: 115px;
    left: 1.25rem;
    width: 100px;
    height: 100px;
    background-color: white;
  }

  
  
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .card-custom{
        min-height: 400px !important; /* Adjust the height as needed */
    }
}