@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700;800&display=swap");



.mn {
 

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #262626;
 
  overflow: hidden;
  padding: 100px;
  width: min(2000px, 100%); 
  padding: 50px; 
  padding-top: 50px;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
  background-color:#ffffff;

  backdrop-filter: blur(30px);
  border-radius: 20px;
  
  width: 100%; /* Ensure content takes full width */
  max-width: 100%; /* Limit maximum width to prevent overflow */

  box-shadow: 0 0.5px 0 1px rgba(255, 255, 255, 0.23) inset,
    0 1px 0 0 rgba(255, 255, 255, 0.66) inset, 0 4px 16px rgba(0, 0, 0, 0.12);
  z-index: 10;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 450px;
  padding: 0 35px;
  text-align: justify;
}

.info p {
  color: #000000;
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 20px;
 
  line-height: 1.5;

}

.movie-night {
  background: linear-gradient(225deg, #ff3cac 0%, #784ba0 50%, #2b86c5 100%);
}

.moviesnightbtn {
  display: block;
  padding: 10px 40px;
  margin: 10px auto;
  font-size: 1.1rem;
  font-weight: 700;
  border-radius: 4px;
  outline: none;
  text-decoration: none;
  color: #784ba0;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  cursor: pointer;
}

.moviesnightbtn:hover,
.moviesnightbtn:focus,
.moviesnightbtn:active,
.moviesnightbtn:visited {
  transition-timing-function: cubic-bezier(0.6, 4, 0.3, 0.8);
  animation: gelatine 0.5s 1;
}

@keyframes gelatine {
  0%,
  100% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
}

/* SWIPER */

.swiper {
  width: 300px;
  height: 450px;
  padding: 50px 0;
  margin: 30px;
}

.swiper-slide {
  position: relative;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  user-select: none;
}

.swiper-slide img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-position {
  object-position: 50% 0%;
}

.overlays {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #0f2027, transparent, transparent);
  background-repeat: no-repeat;
  background-size: cover;
}

.overlays span {
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  padding: 7px 18px;
  margin: 10px;
  border-radius: 20px;
  letter-spacing: 2px;
  font-size: 0.8rem;
  font-weight: 700;
  font-family: inherit;
  background: rgba(255, 255, 255, 0.095);
  box-shadow: inset 2px -2px 20px rgba(214, 214, 214, 0.2),
    inset -3px 3px 3px rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(74px);
}

.overlays h2 {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.4;
  margin: 0 0 20px 20px;
}

/* ANIMATED BACKGROUND */

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background-color: #ff3cac;
  background-image: linear-gradient(
    225deg,
    #ff3cac 0%,
    #784ba0 50%,
    #2b86c5 100%
  );
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

/*
@media (max-width: 750px) {
  .content {
     flex-direction: column-reverse;
  }
  
  .btn {
    margin: 10px auto 40px;
  }
}
*/


/* Media query for small devices */
@media only screen and (max-width: 768px) {
  .mna {
    min-height: 0;
  }

  .info h4 {
    margin-left: 50px;
    margin-top: 30px;
  }

  .info p  {
    margin-left: 50px;   
     margin-right: -5px;
  }
  .moviesnightbtn {
    width: 200px !important; 
    margin-left: 70px;
  }
  .info {
    margin-left: 37px;
  }
  .movienightatag {
    margin-left: -15px;
  }
}


@media only screen and (min-width: 768px) and (max-width: 920px) {
  .info {
    margin-left: -20px;
  }
}

@media only screen and (width: 540px) {
  .info {
    margin-left: -60px;
  }
  .mna {
    min-height: 0;
  }
}

@media only screen and (width: 540px) {
  .info {
    margin-left: -30px;
  }
  .mna {
    min-height: 0;
  }
  .content {
    display: flow !important;
  }
  .swiper {
    margin: 60px;
  }
  .movienightatag {
    margin-left: -30px;
  }
}

@media only screen and (width: 853px) {
  .info {
    margin-left: 25px;
  }
  .mna {
    min-height: 0;
  }
  .content {
    display: grid;
  }
}


@media  only screen and (width: 1280px){
  
  .mna {
    min-height: 0 !important;
  }
}