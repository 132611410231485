.single__free__course:hover {
  transform: scale(1.1);
}
.single__free__course{
  padding: 5px 0px;
}

.free__course__img {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
}

.free__btn {
  position: absolute;
  bottom: -20px;
  right: 20px;
  z-index: 999999;
  padding: 4px 25px !important;
  font-size: 0.8rem;
}

.free__course__img img {
  border-radius: 10px;
  
}


.free__course__details h6 {
  font-weight: 600;
  line-height: 3rem;
  text-align: center;
  /* margin-top: -30px; */
}

/* .free__course__img:hover{
  transform: scale(1.1);
} */

/* .newsndevents-section{
  padding-bottom: 10px;
} */


@media (min-width: 320px) and (max-width: 767px) {

  .newsndevents-section{
padding-top: 0px;
padding-bottom: 20px;
  }
  .free__course__img img {
    margin-top: 10px;}
  .nandevents{}

}
