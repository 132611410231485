.heros-sec {
  padding-top: 35px !important;
}

.hero__content h2 {
  font-weight: 600;
  line-height: 55px;
}
.hero__title h1 {
  color: black;
  text-align: center;
  font-family: "Andada Pro", serif;
}

.hero__title h3 {
  color: #be103c;
  text-align: center;
  font-family: "Andada Pro", serif;
}
.hero__title h5 {
  color: #be103c;
  text-align: center;
  font-family: "Andada Pro", serif;
}

.hero__img {
  padding-top: 10px;
  border-radius: 25px;
}

.hero-content-col {
  padding-left: 25px !important;
  padding-top: 10px;
}

.hero__title {
  font-size: 2.5rem;
  color: #333;
  font-weight: bold;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.search input {
  border: none;
  outline: none;
  padding: 7px 10%;
  border-radius: 50%;
}

.search {
  width: 60%;
  border: 1px solid #17bf9d44;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  margin-top: 20px;
}
