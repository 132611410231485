.navbar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  background-color: #fff;
  height: 80px;
  margin: 20px;
  border-radius: 16px;
  padding: 0.5rem !important;
}
.navbarMenuIcon {
  /* color: #000 !important; */
  padding-right: 5px;
}
.mainmenua{
  color: #fff !important;
  font-size: large !important;
}
.mainmenu{
  /* background: #1a1a1a73; */
  background: #000;
  border-radius: 5px;  
  margin-left: 3px;
  margin-right: 3px;
}
.mainmenu:hover{
  background: none !important;
}
.mainmenu:hover .mainmenua{
  color: #be103c !important;
 
}
.mainmenu:hover .navbarMenuIcon{
  color: #be103c !important;
  transition: none !important;
}

.dropdwnmenu{
  background: #1a1a1a73 !important;
}
.dropdwnmenua{
  color: #fff !important;
  font-weight: bold !important;
}
.dropdwnmenua:hover{
  background: #be103c !important;
}
/* .nav-item{
  background-color: rgb(210, 206, 206);
  border-radius: 10px;
  margin-left: 5px;
  margin-right: 5px;
} */
/* .nav-item:hover{
  background-color: #0f75b3e0;
  border-radius: 10px;
} */
/* .nav-item:hover .nav-item a{
  color: white;
} */
.navcontainer {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.navbar-logo img {
  width: 165px;
}

.navbar-toggler {
  border: none !important;
  padding-right: 0px !important;
  font-size: 1.25rem;
}
.navbar-toggler:focus,
.btn-close:focus {
  box-shadow: none !important;
  outline: none !important;
}
/* .nav-link:hover{
  background-color: rgb(210, 206, 206);
} */

.dropdown-menu {
  width: 200px !important;
  border: none !important;
}
.dropdown-menu li a {
  color: black;
  text-decoration: none;
  padding: 5px 0px 5px 25px !important;
}

.side-bar-div {
  transition: 0.3s ease !important;
}


@media (max-width: 991px) {
  .navcontainer {
    margin-top: -6px !important;
  }
  .mainmenu{
    background: none !important;
    /* margin: 0px !important;
    padding-left: 15px !important; */
  }
  .mainmenua, .navbarMenuIcon{
    color: #000 !important;
  }
  .mainmenua span{
    padding: 5px 10px;
    border-radius: 3px;
  }
  .dropdwnmenu{
    background: none !important;
  }
  .dropdwnmenua{
    color: #000 !important;
  }
  .mainmenu:hover .mainmenua span{
    background: #be103c !important;
  }
  .mainmenu:hover .mainmenua{
    color: #fff !important;
  }
  .mainmenu:hover .navbarMenuIcon{
    color: #fff !important;
  }
  .navbar-nav .dropdown-menu{
    margin-top: -15px !important;
    margin-left: 0px !important;
  }
}

@media (max-width: 768px){
  .offcanvas.offcanvas-end {
    padding-left: 15px;
  }
  .navcontainer{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .navbar{
    height: 64px;
  }
  .navbar-logo img{
    width: 130px;
  }
}
