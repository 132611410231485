.managementherosec{
  background-image: url(../../assests/images/gallery/managbg.svg) !important;
}
.managementherocnt{
  margin-top: 150px;
}
.managementherocnt h2{
  color: #fff !important;
}
.managementherocnt h2 span{
  background: none !important;
}