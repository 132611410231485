.navherosection{
  background-image: url(../../assests/images/gallery/herobgimg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  padding: 0px;
  margin-top: -120px;
}
.navherosection::before{
  background-color: rgba(0, 0, 0, 0.6);
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
}

.navherosection .container{
  height: 100vh;
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.navherosection h1{
  font-size: 1.2em;
  text-align: center;
}
.navherosection h2{
  font-size: 0.5em;
  text-align: center;
  color: #be103c;
}
.navherosection h2 span{
  background: #fff;
  border-radius: 5px;
  padding: 0px 5px;
}