.admissionherosec{
  background-image: url(../../assests/images/gallery/admherobg.jpeg) !important;
}
.admissionherocnt{
  margin-top: -50px;
}
.admissionherocnt h2{
  color: #fff !important;
}
.admissionherocnt h2 span{
  background: none !important;
}