.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* Distribute items evenly along the main axis */
}

.card {
    width: calc(25% - 20px);
    /* 25% width for each grid item with spacing */
    max-width: 400px;
    height: 300px;
    /* background-color: #0b0b0b54; */
    background-color: black;

    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    margin: 10px;
}

.icon {
    font-size: 40px;
    color: white;
    height: 70px;
    text-align: center;
}

.text {
    text-align: center;
    color: white;
}

.hidden-paragraph {
    display: none;
    color: white;
    background-color: rgb(0 0 0 / 24%);

}

.cardOverlay {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: rgb(0 0 0 / 24%);
    


}

.cardOverlay:hover {
    background-color: rgb(0 0 0 / 54%);
}


.card:hover {
    transform: translateY(-10px);
    background-color: black;
    box-shadow: 0px 0px 10px 0px rgba(198, 4, 4, 0.5); /* Change shadow color on hover */

    /* Light gray background color */

}

.card:hover .hidden-paragraph {
    display: block;
    color: white;
}

@media (max-width: 768px) {
    .card {
        width: calc(50% - 20px);
        /* 50% width for each grid item with spacing on smaller screens */
    }
}

@media (max-width: 576px) {
    .card {
        width: calc(100% - 20px);
        /* 100% width for each grid item with spacing on even smaller screens */
    }
}


.transparent-card {
    background-color: transparent;
    /* Set background color to transparent */
    border: none;
    /* Remove border */
    pointer-events: none;
    /* Disable pointer events */
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.text-justify {
    text-align: justify;
  }
  
  @media (min-width: 820px) and (max-width: 1180px) {
    p3 {
        margin: -15px;
    }
  }

  @media (min-width: 100px) and (max-width: 350px) {
    p3 {
        margin: -5px;
    }
  }
  

