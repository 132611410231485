.choose__img img {
  border-radius: 15px;
}

.choose__content {
  padding-right: 50px;
}

.choose__content h2 {
  font-weight: 600;
}
.choose__content p{
margin-top: 15px;
}

.choose__img {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 777;
}




.play__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  z-index: 7777;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}



.play__icon i {
  color: #17bf9e;
  font-size: 2rem;
  padding: 10px;
}

.ri-play-circle-line:hover{
  color: rgb(0, 120, 225);

}
/* .choose__img :hover{
  transform: scale(1.1);
} */

@media (max-width: 991px) {
  .choose__content  {
    padding-right: 0px;
    margin-top: -30px;
    text-align: justify;
    
  }

  
}