.homeherosec{
  background-image: url(../../assests/images/gallery/homehero.JPG) !important;
}
.heroseccontent{
  margin-top: -100px;
}
.homereroh3{
  text-align: center;
}
.homereroh1{
  text-align: center;
  color: #fff;
}
.homereroh5{
  text-align: center;
}