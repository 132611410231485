.acrherosec{
  background-image: url(../../assests/images/gallery/acrbg.png) !important;
}
.acrherocnt{
  margin-top: -100px;
}
.acrherocnt h2{
  color: #fff !important;
}
.acrherocnt h2 span{
  background: none !important;
}