article {
  --img-scale: 1.001;
  --title-color: black;
  --link-icon-translate: -20px;
  --link-icon-opacity: 0;
  position: relative;
  border-radius: 16px;
  box-shadow: none;
  background: #fff;
  transform-origin: center;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
  /* margin-top: 40px; */

}

article a::after {
  position: absolute;
  inset-block: 0;
  inset-inline: 0;
  cursor: pointer;
  content: "";
}

article h2 {

  margin: 0;
  font-size: 1.9rem;
  color: var(--title-color);
  transition: color 0.3s ease-out;
  /* font-family: 'Andada Pro', serif !important; */
}

figure {
  margin: 0;
  padding: 0;
  aspect-ratio: 16 / 9;
  overflow: hidden;
}

article img {
  max-width: 100%;
  transform-origin: center;
  transform: scale(var(--img-scale));
  transition: transform 0.4s ease-in-out;
}

.article-body {
  padding: 24px;
}

.article-body h2 {
  letter-spacing: 0;
}

article a {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #28666e;
}

article a:focus {
  outline: 1px dotted #28666e;
}

article a .icon {
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-left: 5px;
  transform: translateX(var(--link-icon-translate));
  opacity: var(--link-icon-opacity);
  transition: all 0.3s;
}

article:has(:hover, :focus) {
  --img-scale: 1.1;
  --title-color: #28666e;
  --link-icon-translate: 0;
  --link-icon-opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}


.articles {
  display: grid;
  max-width: 1200px;
  margin-inline: auto;
  padding-inline: 24px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 24px;
}

@media screen and (max-width: 960px) {
  article {
    container: card/inline-size;
  }

  .article-body p {
    display: block !important;
  }
}

@container card (min-width: 380px) {
  .article-wrapper {
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 16px;
  }

  .article-body {
    padding-left: 0;
  }

  figure {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  figure img {
    height: 100%;
    aspect-ratio: 1;
    object-fit: cover;
  }
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@media screen and (max-width: 540px),
(max-width: 430px) {
  .article-wrapper {
    display: flex !important;
    /* Stack items vertically */
    flex-direction: column !important;
    align-items: center !important;
    /* Center items horizontally */
    text-align: center !important;
    /* Center text within article */
  }

  figure {
    width: 150% !important;
    /* Ensure the image takes full width */
    gap: 0;
  }

  .article-body {
    text-align: center !important;
    /* Center text within article body */
  }

  article h2 {
    font-size: 1.6rem !important;
    /* Decrease font size for article headings */
    margin-top: 16px !important;
    /* Add margin top to headings */
  }
}
/* @media screen and (min-width: 768px) and (max-width: 920px) {
  .article-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  article {
    flex-basis: calc(50% - 8px);
    margin-bottom: 16px;
  }

  article h2 {
    font-size: 1.9rem;
    margin-top: 0;
  }

  figure {
    width: 100%;
    aspect-ratio: 16/9;
    overflow: hidden;
    margin-bottom: 8px;
  }

  .article-body {
    padding: 24px;
    text-align: left;
  }
} */

@media screen and (min-width: 768px) and (max-width: 912px) {
  .article-wrapper {
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 16px !important;
  }

  article {
    flex-basis: calc(50% - 8px) !important;
    margin-bottom: 16px !important;
  }

  article h2 {
    font-size: 1.9rem !important;
    margin-top: 0 !important;
  }

  figure {
    width: 100% !important;
    aspect-ratio: 16/9 !important;
    overflow: hidden !important;
    margin-bottom: 8px !important;
  }

  .article-body {
    padding: 24px !important;
    text-align: left !important;
  }
}
