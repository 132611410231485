.teachers {
  background: #eeecf0;
}
.acadblankdiv{
  margin-top: 50px;
  padding-top: 10px;
}
.teacherCard {
  background: url(../../assests/images/gallery/tcrbgn.png) no-repeat;
  /* margin: 5px; */
  margin-top: 25px;
  margin-left: 0px;
  margin-right: 0px;
  height: 390px !important;
  box-shadow: none !important;

}
.teacherCard:hover{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px !important;
}

.teacherImage {
  text-align: center;
}

.teacherImage img {
  width: 80%;
  height: 100%;
  padding: 10px;
  border-radius: 25px;
}
.teacherDetails {
  text-align: center;
  padding-top: 10px;
}

.academicsSec-imgdiv img {
  width: 100%;
}
.academicsSec-imgdiv img:hover{
  transform: scale(1.1);
}
.imgdivbdr img{
  background-color: #372a11;
  border-radius: 50%;
  padding: 5px;
  border: 3.2px solid #b0852a;
}
.academicsSec-heading {
  margin-top: 20px;
  text-align: center;
  padding: 10px 0px;
  border-radius: 50px;
}
.yloClr {
  background: #fbde4e;
}
.pnkclr {
  background: #fc7ecf;
  color: #ffffff;
}
.skyclr {
  background: #8cd7e7;
}
.blkclr {
  background: #000000;
  color: #ffffff;
}
.academicsSec-cntdiv p {
  text-align: center;
}
.clm-padng {
  padding: 0px 30px !important;
}
.clm-dwn {
  margin-top: 100px !important;
}


@media (max-width: 990px){
  .clm-dwn {
    margin-top: 0px !important 
   }
}
@media (max-width: 575px){
  .teacherCard {
    height: 100% !important;
    /* margin-top: 10px; */
  }
  .marbottm{
    margin-bottom: 30px;
  }
}