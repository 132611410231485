/* f1.css */

.footerSec{
  background-color: rgba(0, 0, 0, 0.05);
  padding-bottom: 0px;
}
.footerlogo{
  text-align: center;
}
.socialicon{
  padding-left: 25px;
}
/* Custom Instagram icon with gradient background */
.custom-instagram-icon {
  background: linear-gradient(to right, #FEDA75, #FA7E1E, #D62976, #962FBF, #4F5BD5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.my-p1-class{
  color: #000 !important;
    margin-bottom: 0px !important;
}
/* Style the social media icons */
.icon {
  font-size: 22px;
 
}

/* Style the black icons */
.black-icon {
  color: black !important;
}

/* Style the no-underline links */
.no-underline {
  text-decoration: none;
}

/* Style the black text */
.text-black {
  color: black;
}

/* Style the margin for mx-auto mb-4 class */
.mx-auto.mb-4 {
  margin: 25px;
  
}

.text-center text-lg-start text-muted {
  margin-bottom: 0px;
}

.text-center {
  margin-bottom: 0px !important;
}

.text-center text-lg-start text-muted section,
footer {
  padding-bottom: 0px;
}

footer {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.mb-4 .black-text {
  margin-bottom: 0.5rem !important;
}

.my-custom-class {
  margin-bottom: 0.1rem;
  color: #000;
}

.mb-4 {
  margin-bottom: 0px !important;
}


.footer-contact {
  margin-right: 10px;
}

.copyrt{
  background: #000000;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}
.copyrtline{
  color: #ffffff;
  margin-bottom: 0px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .footerlogo{
    text-align: left !important;
  }
}

@media (max-width: 768px){
  .socialiconf{
    padding-left: 25px;
  }
}
