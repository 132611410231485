/* MyCalendarComponent.css */

.result-calendar {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    color: #000000;
    /* width: 400px; */
    /* margin-left: 80px; */
}
.react-calendar{
    width: 100%;
}

.custom-calendar {
    width: 600px;
    margin-top: 20px;
    background-color: #ffffff;
}

.react-calendar__navigation button:hover {
    background-color: #ffffff;
    color: white;
}
