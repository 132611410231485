/* Styles for CurriAchiever component */

/* Section header styles */
.cmodalHeadSec {
    background: #000000;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .cmodalHead {
    color: #ffffff;
    text-align: center;
  }
  
  /* Landing page section styles */
  .cmedfmIcon {
    padding-top: 30px;
  }
  
  .tp-ab-circle-img .ab-circle-one {
    top: 78px;
    left: 32%;
  }
  
  .tp-ab-circle-img {
    margin-left: -50px;
  }
  
  .ab-circle-shape img {
    max-width: 100%;
    height: auto;
  }
  
  .tp-ab-circle-img .ab-circle-two {
    position: absolute;
    right: 21px;
    bottom: 155px;
    z-index: -1;
  }
  
  .tp-sub-title-box {
    padding: 12px 20px;
    font-size: 16px;
    background: rgba(255, 102, 82, .1);
    border-radius: 5px;
    display: inline-block;
    line-height: 1;
  }
  
  .section-title {
    position: relative;
    text-align: center;
    margin-right: 20px;
    z-index: 1;
  }
  
  .tp-section-title {
    font-family: 'YourFontFamily', sans-serif;
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
    display: inline-block;
  }
  
  .tp-section-title::before {
    content: '';
    display: block;
    width: 94px;
    height: 67px;
    background: url("../../assests/ImageCurriclm/approach4.png");
    background-repeat: no-repeat;
    background-color: white;
    margin: 10px auto;
    z-index: -1;
    position: absolute;
    top: -9px;
    left: -45px;
  }
  
  .section-title p {
    margin-bottom: 0;
    line-height: 28px;
    text-align: justify;
  }
  
  .about-circle-list ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    padding: 0px;
    margin: auto;
  }
  
  .about-circle-list ul li {
    width: 50%;
    flex: 0 0 50%;
    font-size: 20px;
    font-family: Gordita-500;
    font-weight: 500;
    color: var(--tp-heading-primary);
    line-height: 1.7;
    position: relative;
    margin-bottom: 25px;
    text-align: center;
  }
  
  .curriculum-circle-img img {
    width: 85%;
    margin-left: 50px;
  }
  
  /* Educational framework icon styles */
  svg:not(:root).svg-inline--fa,
  svg:not(:host).svg-inline--fa {
    overflow: visible;
    box-sizing: content-box;
    color: white;
    width: 20px;
    height: 20px;
  }
  
  .icon-background {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    padding: 10px;
    border-radius: 100%;
    background-color: rgb(16, 100, 100);
    margin-top: 10px;
    margin-left: 40%;
  }
  
  .about-circle-list span {
    display: block;
    margin-top: 27px;
    margin-right: 40px;
  }
  