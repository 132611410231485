
/* .course-section{
  padding-bottom: 0px !important;
} */

.single__course__item {
  padding: 5px,0px;
}
.single__course__item:hover{
  transform: scale(1.05);
}

.course__img img {
  border-radius: 10px;
  
}

/* .course__img:hover {
  transform: scale(1.1);
} */

.enroll a {
  text-decoration: none;
  font-weight: 600;
  font-size: 0.9rem;
  color: #17bf9e;
}

.lesson,
.students,
.rating {
  font-size: 0.9rem;
  color: #0a2b1e;
  font-weight: 500;
}

.lesson i,
.students i,
.rating i {
  font-weight: 400;
}

.course__title {
  line-height: 3rem;
  text-align: center;
}

.course__top__left{
  text-align: center;
  margin-bottom: 25px;
}
.course__top__left h2 {
  font-weight: 600;
}
/* @media (max-width: 576px) {
  .choose__content h2  {
    padding-left: 0px;
    margin-top: -30px;
    text-align: justify;
  }
 
  
}

@media (max-width: 776px) {
  .course__top__left h2{
    margin-top: -20px;
  }
  .section-background {
    margin-top: -15px;
    padding-bottom: 20px;
  }

  .single__course__item{
    margin-top: -25px;
  }
  
} */

@media (max-width: 991px) {

  .course-section{
    padding-bottom: 0px;
  }


}
@media (min-width: 320px) and (max-width: 767px) {

.course__title {
  margin-bottom: 50px;
  
}




}