.galleryherosec{
  background-image: url(../../assests/images/gallery/9_bg.JPG) !important;
}
.galleryherocnt{
  margin-top: -150px;
}
.galleryherocnt h2{
  color: #fff !important;
}
.galleryherocnt h2 span{
  background: none !important;
}