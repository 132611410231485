.achivmentsHead {
  background: #000000;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
}
.achivmentsImgDiv{
  margin: auto !important;
}
.achivmentsImg{
  width: 100%;
}
.achivmentsImg:hover{
  transform: scale(1.1);
}
.achvcntjstfy{
  text-align: justify;
}
.achivmentsFstSec{
  background: #ffffff;
}
.topperHeadsec{
  background: #000000;
  padding-top: 20px;
  padding-bottom: 20px;
}
.topperHead{
  text-align: center;
  color: #ffffff;
}