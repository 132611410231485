.aboutherosec{
  background-image: url(../../assests/images/gallery/aboutbg.jpeg) !important;
}
.aboutherocontent{
  margin-top: 100px;
}
.aboutherocontent h2{
  color: #fff !important;
}
.aboutherocontent h2 span{
  background: none !important;
}