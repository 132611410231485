

.admissionHead{
  background: #000000;
  text-align: center;

  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
}

.admissionMarquee{
  padding-top: 30px;
  padding-bottom: 20px;
  background: #f0edf1;
}

.inlblock{
  padding-right: 20px;
}



.admissionGallery{
  background: #f0edf1;
  padding-top: 50px;
  padding-bottom: 20px;
}
.calanderImg{
  width: 100%;
  border-radius: 30px
}
.admissionOpenImg{
  display: flex;
  margin-top: -100px;
}

.admissionskill{
  padding-top: 50px;
  padding-bottom: 50px;
}

.admissionKeyPointCol{
margin-top: 20px !important;
}
.admissionskillCol{
  position: relative;
  
}

.admissionskillCol::before{
  content: "";
  background-image: url(../../assests/images/gallery/bgtransp.png);
  background-size: cover;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.4;
}
.admissionSkill{
  position: relative;
}
.lifeAtSchool{
  background: #f0edf1;
  text-align: center;
}
.admissionKeyPnt{
  text-align: center;
  color: black;
  margin-top: 20px;
  
  
}
.keypntpara{
  text-align: justify;
  color: #000000;

}
.schoolimage{
  width: 100%;
  border-radius: 25px;
}
/* .schoolimage:hover , .calanderImg:hover{
  transform: scale(1.1);
} */
.schoolimgsec{
  background: #f0edf1;
}


.admissionKeyPntCrd{
  margin: 0px;
    letter-spacing: 0px;
}
.admissionKeyPntCrd:hover{
  transform: scale(1.05);
}
.whtclr{
  /* color: whitesmoke; */
  color: white;
}
.learningbg{
  /* position: relative; */
  background: linear-gradient(rgb(64 63 63 / 80%), rgb(81 80 80 / 80%)), url(../../assests/images/gallery/learning.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.artlabbg{
  
  background: linear-gradient(rgb(64 63 63 / 80%), rgb(81 80 80 / 80%)), url(../../assests/images/gallery/art&lab.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.classroombg{
 
  background: linear-gradient(rgb(64 63 63 / 80%), rgb(81 80 80 / 80%)), url(../../assests/images/gallery/classroom.png);
  background-size: cover;
  background-repeat: no-repeat;
}

