.infra-title{
    text-transform: capitalize;

    background: black;
    height: 100px;
    padding-top: 30px;
  }
  .infra-title-head{
    text-align: center;
    color: white;
  }