.team-pg-area {
  margin: 0 auto;
  padding-bottom: 100px;
  padding-top: 200px !important;
  margin-top: 50px;
}
.team-info-wrap{
  padding-top: 50px;
}
.principalpara{
  color: #1a1729 !important;
}
.team-single-wrap {
  background: #fff;
  box-shadow: 0 1px 15px 0 rgba(62,65,159,.15);
  padding: 0 100px;
}
.align-items-center {
  align-items: center !important;
}

.team-info-img {
  border-left: 50%;
  border-right: 50%;
}

.team-info-img img {
  width: 100%;
}
.team-info-text {
  padding-left: 103px;
  padding-top: 50px; 
}
.team-info-text h2 {
  color: #1a1729;
  font-size: 40px;
  font-weight: 400;
  line-height: 55px;
  margin-bottom: 30px;
  margin-top: 0;
}
.team-info-text ul{
  padding-left: 0px;
}

.team-info-text ul li {
  color: #1a1729;
  font-family: Urbanist;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  list-style: none;
  margin-bottom: 15px;
}
.team-info-text ul li span {
  color: #1a1729;
  display: inline-block;
  font-family: Urbanist;
  font-size: 18px;
  padding-left: 5px;
  position: relative;
}

.team-info-text .certificates-wrap {
  margin-top: 40px;
}

.team-info-text .certificates-wrap h2 {
  font-size: 27px;
  font-weight: 400;
  line-height: 37px;
}
.team-info-text h2 {
  color: #1a1729;
  font-size: 40px;
  font-weight: 400;
  line-height: 55px;
  margin-bottom: 30px;
  margin-top: 0;
}

.team-info-text .certificates-wrap .certificates-items {
  display: flex;
}
.team-info-text .certificates-wrap .certificates-items .certificates-item+.certificates-item {
  margin-left: 20px;
}
/* personal experiance */
.team-widget {
  border-top: 1px solid #d9d9d9;
  margin-top: 40px;
  padding-top: 40px;
}


.exprience-wrap {
  padding-bottom: 40px;
}

.team-widget h2 {
  font-size: 35px;
  font-weight: 400;
  line-height: 48px;
  margin-bottom: 30px;
}

.team-exprience-area .education-area {
  padding-left: 50px;
}

.team-exprience-area .education-area ul {
  list-style: none;
}

.team-exprience-area .education-area ul li {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  padding-bottom: 15px;
  padding-left: 30px;
  position: relative;
}
/* principal skills area */
.skills-area {
  border-bottom: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  margin-bottom: 40px;
  padding-bottom: 40px;
  padding-top: 40px;
}

.exprience-wrap {
  padding-bottom: 40px;
}

.skills-area .wpo-skill-progress .wpo-progress-single {
  margin-bottom: 30px;
  position: relative;
}

.skills-area .wpo-skill-progress .wpo-progress-single h5 {
  color: #525252;
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
}

/* 
progress bar */

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: .75rem;
  background-color: #e9ecef;
  border-radius: .25rem;
}
.skills-area .wpo-skill-progress .wpo-progress-single .progress {
  background: #fcf9ef;
  height: 6px;
  position: relative;
}


.skills-area .wpo-skill-progress .wpo-progress-single .progress .progress-bar {
  background-color: #4540e1;
}
.progress-bar {
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  transition: width .6s ease;
}

.skills-area .wpo-skill-progress .progress-number {
  color: #525252;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  position: absolute;
  right: 0;
  top: -10px;
}

.skills-area .wpo-skill-progress .wpo-progress-single {
  margin-bottom: 30px;
  position: relative;
}

.skill-progress .wpo-progress-single h5 {
  color: #525252;
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
}

@media (max-width:991px){
  .team-info-text {
    padding-left: 0px;
}
.team-exprience-area .education-area {
  padding-left: 0px;
} 
}

@media (min-width:320px)and (max-width:768px) {
  .team-info-text {
    text-align: center;
}

.team-single-wrap{
  padding: 0px;
}
.team-widget{
  text-align: center;
}
}
































@media (min-width: 992px){
.col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
}
}

@media (min-width: 992px){
.col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
}
}