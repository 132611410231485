.counter {
  font-size: 2rem;
  font-weight: 600;
  color: #0a2b1e;
}

.counter__title {
  font-size: 1rem;
  color: #0a2b1e;
  font-weight: 500;
}

.about__content {
  padding-left: 25px;
  /* text-align: justify; */
}

.about__img img {
  border-radius: 15px;
  margin-top: 15px;
}

.about__content h2 {
  font-weight: 600;
  text-align: justify;
  /* margin-top: 25px; */
}

@media (max-width: 991px) {
  .about__content  {
    padding-left: 0px;
    margin-top: 25px;
    text-align: justify;
  }
}
@media(max-width: 320px){
  .abtimgsection{
    padding-top: 25px;
    padding-bottom: 30px;
  }

  
}


