.scrollimgsec{
  padding-bottom: 0px;
  padding-top: 0px;
}
.scrollimgdiv{
  height: 200px;
  overflow: hidden;
}
.scrollimg{
  width: 100%;
  height: 100%;
}